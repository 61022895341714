.holder {
  @apply flex h-screen flex-col m-auto justify-center items-start mx-4 sm:mx-64 lg:mx-96 space-y-4;
}

.header {
  @apply font-bold self-start;
}

.message {
  @apply leading-none;
}
